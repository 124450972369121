@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titan+One&display=swap');
html,
body,
#root,
.app,
.content{
  height: 100%;
  width: 100%;
  font-family: "Roboto", sans-serif;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}

body{
  overflow-x: hidden;
}

#embed-ded-map-canvas img {maxHeight:none;max-width:none!important;background:none!important;}