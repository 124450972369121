/* ---------logo--------- */
.logo-1{
    font-size: large;
    /* font-weight: bolder; */
    font-family: "Titan One", sans-serif;
    /* font-weight: 400; */
    font-style: normal;
    
  }
  .logo-2{
    font-family: "Titan One", sans-serif;  
  }
  
  .logo-1,.logo-2{
    color: #1A551B;
    /* text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.404); */
  }
  
  .logo-3{
    font-size: 50px;
    font-family: "Titan One", sans-serif;  
  }
  .logo-4{
    font-family: "Titan One", sans-serif;  
  }
  /* ---------logo--------- */
  
  .App{
    position: relative;
  }
  
  .header{
    /* position: absolute;
    top: 0; */
    /* border: 1px solid red; */
    /* width: 200px; */
    /* overflow: auto; */
    /* position: relative; */
  }
  
  .header-image{
    /* border: 2px solid red; */
    object-fit: cover;
    /* height: 100%; */
    height: 500px;
    /* height: 80vh; */
    width: 100%;
    filter: brightness(0.6);
    /* position: absolute; */
    /* top: 0; */
  }
  
  .navbar{
    /* border: 1px solid red; */
    /* background-color: white; */
    /* background-color: rgb(250, 250, 250); */
    background-color: rgba(250, 250, 250, 0.687);
    /* position: absolute; */
    /* border: 1px solid red; */
    position: relative;
    /* padding-left: 20px; */
    backdrop-filter: brul(10px);
    
    display: none;
  }
  
  .logo-holder{
    /* border: 1px solid green; */
    /* position: absolute; */
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .logo{
    height: 60px;
    /* border: 1px solid red; */
  }
  
  .logo-title {
    /* border: 1px solid red; */
    /* margin-top: auto; */
    /* display: none; */
  }
  
  .nav-list{
    /* padding: 20px; */
    display: flex;
    align-items: center;
    gap: 30px;
  
  }
  
  .nav-list a{
    text-decoration: none;
    font-weight: 600;
    color: #1A551B;
    font-size: small;
  }
  
  .search-box{
    /* border: 1px solid red; */
    padding: 2px;
    /* border: 1px solid  #1A551B; */
  }
  
  .search-box input{
    border: none;
    padding-left: 5px;
  }
  
  .search-box input:focus{
    outline: none;
    border: none;
  }
  
  .search-box button{
    outline: none;
    border: none;
    background-color: #1A551B;
    color: white;
    font-weight: 600;
    padding: 3px;
    border-radius: 3px;
  }

  .announcements-1{
    display: none;
  }
  
  .announcements, .announcements-1{
    background-color: #1A551B;
    color: yellow;
    padding: 5px;
    display: flex;
    justify-content: center;
    gap: 20px;
    font-style: italic;
    font-size: small;
  }
  
  .select-product-category{
    /* width: 100vw; */
  }
  
  .select-product-buttons{
    /* border: 1px solid red; */
    height:15vh;
    /* width: 100%; */
    
  }
  
  .select-product-buttons  div{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* border: 4px solid red; */
    overflow: hidden;
    transition: all 0.1s ease-out;
    border: 3px solid white;
  }
  
  .select-product-buttons div:hover img{
    transform: scale(1.1);
    opacity: .4;
    z-index: 0;
    cursor:pointer;
  }
  
  .select-product-buttons div:hover .category-title{
    cursor:pointer;
    font-size: xx-large;
    font-weight: bold;
    color:yellow;
    
  }
  
  .select-product-buttons div > img{
    position: absolute; 
    height: 100%;
    width: 100%;
    object-fit: cover;
    transform: scale(1.2);
    filter: brightness(0.8);
    transition: all 0.1s ease-out;
  }
  
  
  
  .select-product-buttons > div:first-child{
  
  }
  .select-product-buttons > div:nth-(2nd){
   
  }
  .select-product-buttons > div:last-child{
   
  }
  
  .product-image-card{
    /* border: 1px solid red; */
    height: 55%;
    width: 50%;
    /* object-fit: cover; */
  }
  
  .product-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 350px;
  }
  
  .product-card img{
    object-fit: contain;
  }
  
  .category-title{
    display: flex;
    align-items: center;
    justify-content: center ;
    color: white;
    font-size: x-large;
    /* font-weight: bold; */
    text-transform: uppercase;
    text-shadow: 2px 2px 5px  black;
    border: none;
    background-color:#1a551baa;
    padding: 10px;
  }
  
  .products{
    padding: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr ;
    gap: 40px;
    /* background-image: url('/src/assets/images/other-assets/background1.jpg');
    background-size: cover;  
    background-position: center;  */
    background-color: #f4f4f4;
  }
  
  .product-info{
    /* padding: 10px; */
    /* border: 1px solid red; */
    text-align: center;
    margin-top: 10px;
  }
  
  .product-title{
    font-weight: bold;
  }
  
  .product-description{
    /* font-weight: bold; */
    /* width: 20%; */
    /* border: 1px solid red; */
    padding: 8px;
    text-align: center;
    color: gray;
    font-style: italic;
    margin-bottom: 5px;
    
  }
  
  .product-top{
    font-style: italic;
  }
  
  
  
  .new-products{
    background-color: white;
    /* border: 1px solid red; */
    display: flex;
    height: 100vh;
  
  }
  
  .new-products div{
    /* border: 1px solid red; */
    width: 50%;
    border-radius: 0;
  }
  .flyer{
    overflow: hidden;
  }
  
  .flyer img{
    height: 100vh;
    width: 100%;
    object-fit: cover;
    transform: scale(1.07);
  
  }
  
  .liquer{
    width: 40%;
    /* border: 1px solid rgb(135, 68, 68); */
    /* background-color: red; */
    /* box-shadow: 0 0 2px black; */
    display: inline-block;
  }
  
  .liquer img{
    height: 100%;
    width: 100%;
  }
  
  .animated-1{
    /* border: 1px solid rgb(30, 0, 255); */
    position: relative;
  }
  
  .liquers{
    /* border: 1px solid red; */
  }
  
  .display{
    position: absolute;
    background-color: #0F731C;
    width: 80%;
    color: #F4D503;
    flex-direction: column;
  }
  .display span{
    display: inline;
    font-family: "Titan One", sans-serif;
  }
  
  .display span:first-child{
    font-size: 60px;
  }
  .display span:last-child{}
  
  .content-1{
    padding: 60px;
  }
  
  .centered-div{
    /* border: 1px solid rgb(0, 255, 132); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .centered-div div{
    /* border: 1px solid red; */
    width: 50%;
    text-align: center;
  }
  
  .centered-div div:first-child{
    font-weight: bolder;
    color: #1A551B;
  }
  
  .centered-div div:last-child{
    font-size: 40px;
    font-weight: bolder;
    color: #0f4873;
  }
  
  .flex-1{
    display: flex;
    flex-wrap: wrap;
  }
  .flex-2{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .cards{
  justify-content: space-around;
  }
  
  .flex-1 > div{
    /* border: 1px solid rgb(52, 102, 219); */
    width: 32%;
  }
  
  .card-icon-holder{
    height: 200px;
    overflow: hidden;
    /* border: 1px solid red; */
    background-color: #072f4d;
    border-radius: 5px;
  }
  
  
  .image-holder{
    padding: 0;
    /* border: 2px solid green; */
    /* height: ; */
  }
  
  .image-holder img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* object-position: 50% 20%; Adjust the position */
    /* height: 100%; */
  }
  
  .card-info{
    padding:20px;
  }
  
  .card-icon-holder,.card-info{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* 
  .card{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 10px;
  } */
  
  .card-2{
    border-radius: 5px;
    padding: 10px;
  }
  
  .card-icon-holder img{
    height: 70%;
  }
  
  .guid-1{
    border: 1px solid red;
  }
  .guid-2{
    border: 1px solid rgb(0, 255, 76);
  }
  
  .column-2{
    display: flex;
  }
  
  .column-50{
    width: 50%;
    position: relative;
    /* display: flex;
    align-items: center;
    justify-content: center; */
  }
  
  .header-title{
    font-weight: bolder;
    color: #0f4873 ;
    /* font-size: xx-large; */
    /* font-size: 2rem; */
    
  }
  .header-title-2{
    font-weight: bolder;
    color: #33648a;
    font-size: xx-large;
    
  }
  .header-title-4{
    font-weight: bolder;
    color: #33648a;
    font-size: x-large;
    
  }
  
  .image-style-1{
    width:40% ;
    /* border: 1px solid blue; */
    /* margin:auto */
    left: 30px;
    /* background-color: green; */
    transform: rotate(30deg);
  }
  
  .image-style-2{
    width:40% ;
    /* border: 1px solid blue; */
    position: absolute;
    top: 30px;
    transform: rotate(-30deg);
  
  }
  .image-style-3{
    width:40% ;
    position: absolute;
    top: 30px;
  }
  
  .candies{
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .basic-grid-1{
    display: grid;
    justify-content: center; /* Centers grid horizontally */
    align-content: center;
    grid-template-columns: 20% 60%;
    column-gap: 20px;
    row-gap: 30px;
  }
  
  .padding-1{
    padding: 20px;
  }
  .padding-2{
    padding: 40px;
  }
  
  .image-2{
    width: 60%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .flex-center-both{
    justify-content: center;
    align-items: center;
  }
  .flex-center-horizontal{
    justify-content: center;
    gap: 20px;
  }
  
  .background-section-1 {
    background-image: url('/src/assets/images/yego_37.JPG');
    background-size: cover; /* This makes sure the image covers the entire section */
    background-position: center; /* This centers the image within the section */
    background-repeat: no-repeat; /* This prevents the image from repeating */
    width: 100vw;
  }
  
  .background-section-1::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    z-index: 1; /* Ensure the overlay appears on top of the background image */
  }
  
  .background-section-1{
    position: relative;
    z-index: 2; /* Ensure the content appears on top of the overlay */
  }
  
  .header-title-3{
    /* color: white; */
    color: #F4D503;
    z-index: 3;
    position: relative;
    font-size: 50px;
  }
  
  .about-image-container-1{
    /* width: 40px; */
  /*  overflow: hidden; */
  min-height: 450px;
  width: 50%;
  position: relative;
  }
  
  .large-image{
    object-fit: cover;
    height: 100%;
    width: 100%;
  position: absolute;
  
  }
  
  .about-1{
  /* border: 2px solid yellow; */
  width: 100%;
  display: flex;
  position: relative;
  }
  
  .about-1 >div:first-child{
    width: 40%;
  }
  .about-1 >div:last-child{
    width: 55%;
    /* border: 1px solid red; */
    padding: 40px;
  }
  
  .card-info-2{
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .card-info-2 p{
    /* border: 1px solid red; */
    margin: 2px;
   
  }
  
  .card-info-2 > div{
    /* border: 1px solid red; */
    margin-top: 3px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
    font-weight: bolder;
  }
  
  .emproyee-name{
    background-color: #f4f4f4;
  }
  .emproyee-post{
    background-color: #072f4d;
    color: aliceblue;
  }
  
  .contact-form{
    width: 100%;
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
  }
  
  .contact-form input, .contact-form textarea{
    padding: 8px;
    background-color: #f4f4f4;
    border: none;
  }
  
  .contact-form input::placeholder, .contact-form textarea::placeholder {
    color: #b1b0b0; /* Placeholder text color */
    font-weight: bolder;
    font-size: small;
  }
  
  .contact-form [type="submit"]{
    background-color: #085412;
    color: whitesmoke;
    font-weight: bolder;
    
  }
  
  
  .testimonials{
    /* border: 1px solid blue; */
    width: 100%;
    justify-content: space-around;
  }
  
  .testimonials > div{
    /* border: 1px solid blue; */
    width: 45%;
    /* padding: 10px; */
    background: #fefefe;
    border-radius: 15px;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
    padding: 50px;
    font-weight: bolder;
  }
  
  .testimonials > div > div{
    margin-top: 2em;
  }
  
  .testimonial-info{
    display: flex;
    gap: 1em;
    align-items: center;
  }
  
  .testimonial-info img{
    border-radius: 50%;
    height: 80px;
    width: 80px;
    object-fit: cover;
  }
  
  .hidden{
    display: none !important;
  }
  
  .testimonial-info div{
    display: flex;
    flex-direction: column;
  }
  
  .testimonial-info div span:first-child{
    font-weight: bold;
  }
  .testimonial-info div span:last-child{
    color: #8c8c8c;
  }
  
  .footer{
    /* border: 1px solid red; */
    display: flex;
    background-color: #030820;
  }
  
  .footer-1{
    padding: 100px 50px;
  }
  
  .footer-1 div{
    width: 25%;
    color: white;
  }
  
  .links{
    display: flex;
    flex-direction: column;
  
  }
  
  .links > span {
    padding: 3px;
    font-weight: 100;
  }
  .links > span:first-child {
    font-weight: bolder;
    font-size: large;
  }
  
  .footer-2 > div{
    display: flex;
    width: 50%;
    border-top: 1px solid white;
    height: 100px;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 20px;
  }
  
  .social-media-links{
    display: flex;
    gap: 2vw;
  }
  
  .headshot-holder{
    height: 400px;
    /* border: 1px solid red; */
    background-color: #072f4d;
    border-radius: 5px;
  }
  
  
  .article-type{
    background-color: #072f4d;
    color: whitesmoke;
    font-family: "Titan One", sans-serif;;
    display: inline-block;
    padding: 10px 20px;
  }
  
  .article-content{
    background-color: whitesmoke;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 10px 20px;
  }
  
  .full-alticle{
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-image: url('/src/assets/images/other-assets/background1.jpg'); */
    position: relative;
  }
  
  .full-alticle img{
    height: 100%;
    width: 100%;
    position: absolute;
    object-fit: cover;
    filter: brightness(0.6);
    z-index: 2;
  
  }
  .article-content a{
    color: white;
    z-index: 3;
    font-weight: bold;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  .lede{
    /* color: #004142; */
    padding: 10px;
  }
  
  .location-table{
    /* border: 1px solid blue; */
  }
  
  .location-table td{
    text-align: center;
    /* border:1px solid red; */
    color: whitesmoke;
    padding: 5px;
  }
  
  .location-section{
    /* border: 1px solid blue; */
    background-color: #072f4d;
  }
  
  .countup-div{
    /* border: 1px solid red; */
    display: flex;
  }
  
  .countup-div> div{
    /* border: 1px solid red; */
    display: flex;
    width: 25%;
    padding: 20px;
    gap: 5px;
    background-color: #072f4d;
  }
  
  .countup-div> div span{
    color: white;
    font-size: x-large;
    /* font-weight: bolder; */
    font-family: "Titan One", sans-serif;
    text-shadow: 0 0 15px black;
  }
  
  
  .candies-list li{
    padding-left: 20px; /* Adjust padding to make space for image */
    background: url('/src/assets/images/other-assets/candy.png') no-repeat left center; /* Specify image as bullet */
    background-size: contain; /* Adjust size of the image */
    margin-bottom: 10px; /* Optional: Adjust spacing between list items */
    padding-left: 30px;
  }
  
  .juice-list{
    padding-left: 0vw;
  }
  .juice-list li{
    background: url('/src/assets/images/other-assets/juice-cup.png') no-repeat left center; /* Specify image as bullet */
    /* background-size: contain;  */
    background-size: 30px;
    margin-bottom: 10px; /* Optional: Adjust spacing between list items */
    padding-left: 2vw;
  }
  
  
  .social-media-links a{ 
    color:white
  }
  
  .footer-1 a{
    color: white;
    text-decoration: none;
    transition: all .3s ease-in-out;
  }
  
  .footer-1 a:hover{
    font-weight: bold;
   
  }
  
  .article-page{
    /* margin-bottom: 30px; */
  }
  
  .article-header{
    height: 100vh;
    /* border: 2px solid red; */
    display: flex;
  }
  
  .article-header > div{
    background-color: #085412;
    height: 100%;
    width: 50%;
    /* border: 2px solid yellow; */
  }
  
  .article-header > div:first-child img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .article-header > div:last-child{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    padding: 50px;
    gap: 20px;
  }
  .article-header > div:last-child > span:first-child{
    font-size: 50px;
    color: white;
    /* text-align: center; */
    font-weight: bold;
  }
  .article-header > div:last-child > span:nth-child(2){
    color: white;
  
  }
  .article-header > div:last-child > span:nth-child(3){
    font-size: small;
    color: white;
    /* border: 1px solid red; */
    margin-left: 0;
  
  }
  
  .article-main {
    /* border: 1px solid red; */
  }
  
  
  .sidebar{
    position: fixed;
    top: 0;
    right: 0;
    /* margin-left: 0; */
    height: 100vh;
    width: 250px;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: -10px 0 10px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px ;
  }
  
  .sidebar li{
    width: 100%;
    border: 1px solid red;
  }
  .sidebar a{
    width: 100%;
  }
  
  .contact{
    /* border: 5px solid green; */
    padding: 60px !important;
  }
  
  .contact > div{
    width: 50% !important;
  }
  
  
  .contact-us{
    width: 50%;
    /* border: 1px solid red; */
  }
  .contact-us-1{
  display:'flex';
  justify-content:'center';
  }
  
 

  .image-component{
    /* border: 2px solid red; */
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    
  }
  .card-image{
    height: 100% !important;
    width:  100% !important;
    object-fit: cover;
  }
  .image-description{
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    /* display: none; */
    opacity: 0;
    /* transition: all 0.3s ease-in-out; */
    padding: 20px;
    /* font-family; */
  }
  .image-component:hover img{
    scale: 1;
    transition: all 0.3s ease-in-out;
  }
  
  .image-component:hover img{
    scale: 1.2;
  }
  
  .image-component:hover .image-description{
    /* top: 0; */
    color: white;
    background: linear-gradient(45deg ,rgba(0, 0, 0, 0.349) 0 50%, transparent 90% 100% );
    /* display: block; */
    opacity: 1;
    cursor: pointer;
    display: flex;
    align-items: end;
    transition: all 0.3s ease-in-out;
    
  }
  
  .image-description .title{
    /* border: 1px solid red; */
  }
  .image-description .title h2{
    /* font-weight:normal; */
    font-size: small;
  }
  .image-description .title h4{
    font-weight:x-small;
  }
  .clipped{
    position: relative;
    /* overflow: hidden; */
  }
  
  .clipped .column-50, .clipped .header-title{
    color: white !important;
    /* border: 1px solid red; */
  }

  .clipped .header-title{
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  }

  .clipped h6,.text-shadow{
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  }
  .box-shadow{
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);

  }
  
  .clipped::before{
    content: '';
    position: absolute;
    inset: 0;
    background-color: #072f4d;
    clip-path: circle(175% at 200px -1040px);
  
  }

  .guids{
    border: 5px solid red !important;
    /* color: green; */
  }
  
  
  
  
  
  @media only screen and (max-width: 1216px) {
    .hidden-3{
      margin-top: 100px;
      height: 20px;
      display: block !important;
      border: 2px solid red !important;
    }
    
    .search-box{
      display: none;
    }
    .flex-1 {
      display: flex;
      flex-wrap: wrap;
    }
  
    .cards{
      /* width: 75rem; */
    }
  
    .content-1{
      padding: 40px 60px;
    }
    .content-2{
      padding: 20px 60px;
    }
  
  
    .logo-title {
      /* border: 4px solid red; */
      display: none;
    }

    .clipped::before{
      content: '';
      position: absolute;
      inset: 0;
      background-color: #072f4d;
      clip-path: circle(175% at 200px -560px);
    
    }

    .juice-list li{
      background: url('/src/assets/images/other-assets/juice-cup.png') no-repeat left center; /* Specify image as bullet */
      /* background-size: contain;  */
      background-size: 30px;
      margin-bottom: 10px; /* Optional: Adjust spacing between list items */
      padding-left: 4vw;
    }
  
    .flex-3{
      display: flex;
      flex-direction: column;
    }

    .hidden-2{
      display: none;
    }
    .special-0{
      width: 100% !important;
      /* border: 2px solid red; */
    }
    
    .flyer{
      overflow: hidden;
    }

    .products{
      padding: 40px;
      display: grid;
      grid-template-columns: 1fr 1fr ;
      gap: 30px;
      background-color: #f4f4f4;
    }
  
  
  }
  
  
  @media(min-width: 801px){
    .sidebar{
      display: none;
    }
  
    .onLargeScreen{
      display: none;
    }
  }
  @media(max-width: 800px){
    .onMobile{
      display: none;
    }
    .onMobileCenter{
      transform: translateY(-190%);
    }
    .onMobileSpecial1{
      transform: translateY(-290%);
    }
  }
  
  
  
  
  
  @media only screen and (max-width: 768px) {
    
    .announcements{
      display: none;
    }
    .announcements-1{
      display: block;
    }
    .flex-1 {
      display: flex;
      flex-wrap: wrap;
    }
  
    .cards{
      /* width: 75rem; */
    }
  
    .content-1{
      padding: 40px 60px;
    }
  
    /* .column-2{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    } */
  
  
    .logo-title {
      /* border: 4px solid red; */
      display: none;
    }
  
  }
  
  @media(max-width: 450px){
    .sidebar{
      display: none;
    }
    .centered-div{
  
    }
    .centered-div div{
      /* border: 1px solid red; */
      width: 100%;
      text-align: left;
    }
  
    .flex-1{
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    gap: 20px;
  }
  
  /* .cards{
  justify-content: space-around;
  border: 4px solid red;
  } */
  
  .card{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 10px;
    /* border: 4px solid red !important; */
    width: 100% !important;
    /* background-color: red; */
    /* display: none; */
  }
  
  .column-2{
    display: flex;
    flex-direction: column;
  }
  
  
  .column-50{
    width: 100%;
    position: relative;
    /* border: 1px solid red; */
  }
  
  .card-2{
    border-radius: 5px;
    padding: 10px;
    width: 100% !important;
  }
  
  .juice-list li{
    background: url('/src/assets/images/other-assets/juice-cup.png') no-repeat left center; /* Specify image as bullet */
    /* background-size: contain;  */
    background-size: 30px;
    margin-bottom: 10px; /* Optional: Adjust spacing between list items */
    padding-left: 8vw;
  }
  
  
  .basic-grid-1{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .candies{
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  .testimonials{
    /* border: 1px solid blue; */
    width: 100%;
    /* justify-content: space-around; */
  }
  
  .testimonials > div{
    /* border: 1px solid blue; */
    width: 100%;
    /* padding: 10px; */
    background: #fefefe;
    border-radius: 15px;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
    padding: 0;
    font-weight: bolder;
  }
  
  

  .countup-div{
    /* border: 1px solid red; */
    display: none;
  }
  
  .footer{
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    background-color: #030820;
  }
  
  .footer-1 div{
    width: 100%;
    color: white;
  }
  
  
  .about-1{
    /* border: 2px solid yellow; */
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 20px;
    }
    .about-image-container-1{
    min-height: 450px;
    width: 100% !important;
    position: relative;
    /* border: 1px solid red ; */
    }
  
    .phone-size-1{
      width: 100%;
      /* border: 1px solid red; */
    }
  
    /* .mission-vision-container{
      border: 2px solid blue;
    } */
  
    .about-1 >div:first-child{
      width: 100%;
    }
    .about-1 >div:last-child{
      width: 100%;
      /* border: 1px solid red; */
      padding: 0px;
    }
  
    .display{
      position: absolute;
      background-color: #0F731C;
      width: 80%;
      color: #F4D503;
      flex-direction: column;
      display: none !important;
    }
  
  
    .new-products{
      background-color: white;
      /* border: 1px solid red; */
      display: block;
      height: 100vh;
    
    }
  
    .new-products div{
      /* border: 1px solid red; */
      width: 100%;
      border-radius: 0;
      /* display: none; */
    }
  
    .liquer{
      width: 40%;
      display: none;
    }
  
    .flyer{
      overflow: hidden;
      /* border: 2px solid red; */
      /* width: 100; */
    }
    
    .flyer img{
      height: 100vh;
      width: 100%;
      object-fit: cover;
      transform: scale(1.07);
    
    }
  
    .products{
      padding: 40px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      background-color: #f4f4f4;
    }
  
    .select-product-category{
      display: none;
  
    }
  
    .onMobileFullWidthDiv{
      border: 2px solid red !important;
      width: 100% !important;
    }
  
    .contact{
      /* border: 5px solid green; */
      padding: 10px !important;
    }
  
    .contact > div{
      width: 100% !important;
    }
    
    
    .contact-us{
      width: 50%;
      /* border: 1px solid red; */
    }
    .contact-us-1{
    display:'flex';
    justify-content:'center';
    }
  
    .article-header{
      min-height: 80vh;
      /* border: 2px solid red; */
      display: flex;
      
    }
  
    .article-header .article-image{
      display: none;
    }
    
    .article-header > div{
      background-color: #085412;
      height: 100%;
      width: 100%;
      /* border: 2px solid yellow; */
    }
    .clipped{
        padding-bottom: 100px;
    }
    .clipped::before{
        content: '';
        position: absolute;
        inset: 0;
        background-color: #072f4d;
        clip-path: circle(175% at 200px -040px);
        
      
      }
  
  }